export const en = {
  shared: {
    yes: 'Yes',
    no: 'No',
  },
  statDialog: {
    title: 'Measurement Statistics: {{date}}',
    minimum: 'Minimum',
    maximum: 'Maximum',
    average: 'Average',
    measurementType: 'Measurers',
  },
  import: 'Import',
  mapHeaderBanner: {
    sitesCount: '{{sitesCount}} sites',
    structuresCount: '{{structuresCount}} equipped structures',
    alertsCount: '{{alertsCount}} threshold alerts activated',
    structureSelection: 'Select a Structure',
    lastMeasurementSeen: 'Last Measurements',
    btnNoMeasurementSeen: 'Measurements',
  },
  navigationMenu: {
    home: 'Home',
    sites: 'Sites',
    companies: 'Companies',
    measures: 'Measurements',
    alarms: 'Alarms',
    devices: 'Devices',
    structures: 'Structures',
    measurers: 'Measurers',
    users: 'Users',
    alerts: 'Alerts',
    parcStatus: 'Park Status',
    sitesDetails: 'Site Details',
    addSite: 'Add a site',
    structuresDetails: 'Structure Details',
    usersDetails: 'User Details',
    companiesDetails: 'Company Details',
    addCompany: 'Add company',
    alarmTriggerDetails: 'Alarm Trigger Details',
    deviceDetails: 'Device Details',
    measurersDetails: 'Measurer Details',
    faq: 'FAQ',
    contact: 'Contact',
    accountDropDown: {
      accountManagement: 'Account Management',
      logout: 'Logout',
      teamOwner: 'Team Owner',
    },
  },

  availableLanguages: {
    en: 'English',
    fr: 'French',
  },
  logging: {
    login: 'Solices Connection Starter',
    username: 'Username',
    password: 'Password',
    loginBtn: 'Login',
  },
  notification: {
    error: 'Error',
    http_error: {
      err_fr_too_many_redirects: 'Server error, contact your administrator',
      err_bad_option_value: 'Server error, contact your administrator',
      err_bad_option: 'Server error, contact your administrator',
      err_network: 'Connection error, check your internet connection',
      err_deprecated: 'Server error, contact your administrator',
      err_bad_response: 'Server error, contact your administrator',
      err_bad_request: 'Server error, contact your administrator',
      err_not_support: 'Server error, contact your administrator',
      err_invalid_url: 'Server error, contact your administrator',
      err_canceled: 'Request canceled by your browser',
      econnaborted: 'Server error, contact your administrator',
      etimedout:
        'Request canceled: too long duration, check your internet connection',
      unknown_error: 'Unknown error',
      unauthorized_error: 'This action requires being logged in',
      forbidden_error: 'Insufficient rights to access the resource',
      notfound_error: 'Resource not found',
      conflict_error: 'This item already exists',
    },
    success: 'Success',
    create: {
      error: 'Creation error: ',
      success: 'Creation successful',
    },
    update: {
      error: 'Update error: ',
      success: 'Update successful',
    },
    delete: {
      error: 'Deletion error: ',
      success: 'Deletion successful',
    },
  },
  authentication: {
    checkAuthentication: 'Connection pending',
  },
  datePlaceholder: 'dd/mm/yyyy',
  navigation: {
    goBack: 'Back',
    validate: 'Validate',
    delete: 'Delete',
  },
  device: {
    device: 'Device',
    deviceIdentifier: 'Identifier',
    deviceName: 'Name',
    serialNumber: 'Serial number',
    iotNetwork: 'IoT Network',
    lastMeasurementDate: 'Last Measurement',
    lastMeasurementDurationDetails: 'Duration',
    location: 'Location',
    nbrAlarms: 'Number of Alarms',
    add: 'Import a Device',
    goToChildren: 'View Sensors associated with this device',
    synchronizeBtn: 'Synchronize',
    noOptionsText: 'No devices found',
    synchronizeDialogContent:
      'This action may take several seconds. Are you sure ?',
    explanation1: 'Step 1: Search for a tag by its IMEI number',
    explanation2:
      'After importing the tag, you will be redirected to importing sensors',
    explanation3: 'Step 3: Finalizing the configuration',
    explanation4:
      'By pressing the Synchronize button, you import the data history and start automatic data updating',
  },
  GPRS: {
    iotAddress: 'IOT GPRS address',
  },
  location: {
    company: 'Company',
    site: 'Site',
    structure: 'Structure',
    locationName: 'Name',
    locationType: 'Type',
    parentLocation: 'Society',
    geoLocation: 'Geographical Coordinates',
    yLat: 'Latitude',
    xLon: 'Longitude',
    nbrAlarms: 'Number of Alarms',
    search: 'Enter an address',
    address: {
      street: 'Street',
      postalCode: 'Postal Code',
      countryCode: 'Country',
      city: 'City',
      googleFormattedAddress: 'Complete Address',
    },
  },
  alarm: {
    measurerId: 'Sensor',
    deviceIdentifier: 'Device Identifier',
    measurementType: 'Data Type',
    eventDate: 'Date',
    alarmState: 'State',
    add: 'Add an Alarm',
    activate: 'Activate an Alarm',
    notifiedEmails: 'Emails to notify',
    notifiedPhoneNumbers: 'Telephone numbers to notify',
    noAlarm: 'No alarm',
    deviceName: 'Device',
    measurementValue: 'value',
    thresholdValue: 'Threshold',
  },
  alarmTrigger: {
    measurer: 'Sensor',
    thresholdValue: {
      fieldValue: 'Alarm Threshold',
    },
    hysteresisValue: {
      fieldValue: 'Hysteresis',
    },
    boundDirection: 'Trend',
    severityLevel: 'Alert Level',
    synchronize: 'Synchronize',
  },
  measurer: {
    measurerCategory: 'Category',
    import: 'Import a sensor',
    measurementType: 'Measured Data',
    alarmState: 'Alarm State',
    device: 'Device',
    label: 'Legend',
    lastAlarmDate: 'Last Alarm',
    measurementDataType: 'Data Type',
    measurementUnit: 'Measurement Unit',
    saveAndConfigureNext: 'Save and configure the following sensor',
    saveAndAddNewMeasurer: 'Save and add other sensors',
    saveAndFinishConfiguration: 'Save and finish tag configuration',
    explanation1: 'Step 2: Import sensor pack',
    explanation2:
      'Here you can select groups of sensors, you will be redirected to the configuration of these sensors.',
    specificParameters: {
      max_analog_ma: 'Max Analog (mA)',
      min_numeric_meter: 'Min Numeric (m)',
      max_numeric_meter: 'Max Numeric (m)',
      min_analog_ma: 'Min Analog (mA)',
      reference_void_level: 'Reference Void Level',
      reference_water_level: 'Reference Water Level',
      battery_full_volt: 'Battery Full Voltage',
      valeur_pleine_echelle: 'Full Scale Value',
      mesure_manuelle: 'Manual Measurement',
      reference_ngf_level_meter: 'Reference ngf level (m)',
      reference_water_depth_meter: 'Reference water depth (m)',
      reference_water_height_meter: 'Reference water height (m)',
      calibration_water_height_meter: 'Calibration water height (m)',
      calibration_date: 'Date of commissioning',
      longueur_surverse_meter: 'Length of overflow (m)',
      largeur_canal_meter: 'Channel width (m)',
      hauteur_pelle_meter: 'Shovel height (m)',
      coefficient_debit: 'Flow coefficient',
    },
    package: 'Module',
    packageName: {
      starter_pack: 'Starter Pack Module',
      void_level_from_water_level: 'Void Level from Water Level',
      water_level_analog: 'Analog Water Level',
      void_level: 'Void Level',
      water_level_from_void_level: 'Water Level from Void Level',
      temperature: 'Temperature',
      water_height_relative: 'Relative water height',
      water_depth_relative: 'Relative water depth',
      water_depth_ngf: 'Water depth ngf',
      water_height_nbf: 'Water height ngf',
      debit_deversoir_noye: 'Flooded spillway flow',
      debit_deversoir_denoye: 'Dry spillway flow',
      debit_orifice_denoye: 'Dry orifice flow',
    },
    add: 'Add a Sensor',
    goToChildren: 'View Alarms for this Sensor',
  },
  validator: {
    empty: '{{param}} cannot be empty',
    enum: 'Incorrect data',
    selectLocationLink: 'You must select a parent location',
    selectDeviceLink: 'You must select a device',
    selectMeasurer: 'You must select a sensor',
    website: 'Must start with http:// or https://',
  },
  company: {
    identificationNumber: 'SIRET',
    companyCode: 'Code',
    legalForm: 'Legal Form',
    phoneNumber: 'Phone Number',
    email: 'Email',
    website: 'Website',
    create: {
      error: 'Error creating company: ',
      success: 'You have created a company',
    },
    add: 'Add a Company',
  },
  site: {
    code: 'Code',
    add: 'Add a Site',
    goToChildren: 'View Devices attached to this site',
  },
  structure: {
    structureCode: 'Code',
    comment: 'Comment',
    structureType: "Type d'ouvrage",
    add: 'Add a Structure',
    goToChildren: 'View Devices attached to this structure',
  },
  locationType: {
    structure: 'Structure',
    company: 'Company',
    site: 'Site',
  },
  alarmState: {
    ok_no_alarm: 'No Alarm',
    upper_bound_minor: 'Upper Bound, Minor',
    upper_bound_major: 'Upper Bound, Major',
    lower_bound_minor: 'Lower Bound, Minor',
    lower_bound_major: 'Lower Bound, Major',
  },
  alarmBoundDirection: {
    upper_bound: 'Upper Bound',
    lower_bound: 'Lower Bound',
  },
  alarmSeverityLevel: {
    minor: 'Minor',
    major: 'Major',
  },
  measurementType: {
    pressure: 'Pressure',
    pressure_analog: 'Analog Pressure',
    water_level: 'Water Level',
    temperature: 'Temperature',
    humidity: 'Humidity',
    battery: 'Battery',
    battery_percent: 'Battery Percentage',
    network_coordinates: 'Network Coordinates',
    keep_alive: 'Keep Alive',
    co2: 'CO2',
    cov: 'COV',
    external_temperature_channel_1: 'External temperature channel 1',
    external_temperature_channel_2: 'External temperature channel 1',
    void_level: 'Void level',
    water_level_analog: 'Analogic water level',
    temperature_analog: 'Analogic temperature',
    water_height: 'Water height',
    water_depth_relative: 'Depth',
    water_depth_ngf: 'Height from NGF',
    water_height_relative: 'Water height relative',
    water_height_ngf: 'Water height ngf',
    water_flow: 'Water flow',
  },
  measurerCategory: {
    sensor: 'Sensor',
    calculator: 'Calculator',
    sounder: 'Status',
  },
  measurementUnit: {
    degree: 'Degree',
    hectopascal: 'Hectopascal',
    percent: 'Percentage',
    volt: 'Volt',
    lux: 'Lux',
    millimeter: 'Millimeter',
    meter: 'Meter',
    latitude_longitude: 'Latitude Longitude',
    ppm: 'PPM',
    ampere: 'Ampere',
    second: 'Seconds',
  },
  structureType: {
    surface_water: 'Surface Water',
    groundwater: 'Groundwater',
    conduct: 'Conduct',
  },
  user: {
    login: 'Login',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    secondEmail: 'Email 2',
    mobilePhoneNumber: 'Mobile Phone',
    fixedPhoneNumber: 'Fixed Phone',
    code: 'Code',
    company: 'Company',
    createdDate: 'Created Date',
    lastModifiedDate: 'Last Modified Date',
    createdBy: 'Created By',
    lastModifiedBy: 'Last Modified By',
    activated: 'Activated',
    langKey: 'Language',
    activatedState: {
      true: 'Yes',
      false: 'No',
    },
    add: 'Add a User',
    authorities: 'Role',
    user: 'User',
    admin: 'Administrator',
    manager: 'Manager',
    location: 'Site/Structure',
    addUserLocation: 'Add',
    permissions: 'Permissions',
    permission: {
      create: 'Create',
      read: 'Read',
      update: 'Update',
      delete: 'Delete',
      cancel: 'Cancel',
      all: 'All',
    },
  },
  dataGrid: {
    action: 'Action',
  },
  modal: {
    deleteTitle: 'Confirm Deletion',
    confirmDelete: 'Are you sure you want to delete this item?',
  },
  measurement: {
    filterPeriod: {
      last7days: '7d',
      lastWeek: '1w',
      lastMonth: '1m',
      lastSemester: '6m',
      lastYear: '1y',
      aec: 'aec',
      max: 'max',
    },
    dateRange: {
      beginDate: 'Start Date',
      endDate: 'End Date',
    },
    deviceIdentifier: 'Devices',
    deviceName: 'Devices',
    measurementType: 'Type',
    period: 'Date',
    measurementDate: 'Date',
    measurerValue: 'Value',
    averageValue: 'Value',
    measurer: 'Sensor',
    chooseMeasurer: 'Choose a Sensor',
    validate: 'Validate',
    seeGraph: 'Show Graph',
    seeChronicles: 'Show Chronicles',
    compareMeasures: 'Compare measurements',
    statistics: 'statistics',
    gotoMap: 'Map',
  },
  googleMap: {
    satellite: 'Satellite layer',
    infoWindow: {
      goTo: 'View Site',
    },
  },
  pwa: {
    calibration_date: 'Calibration',
    updateFrequency: 'Update frequency (hours)',
  },
  image: {
    fileName: 'Name',
    mediaType: 'Type',
    upload: 'Add',
    download: 'Download',
    delete: 'Delete',
  },
};

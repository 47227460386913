import { LocationType } from 'constants/LocationType';
import { MeasurementType } from 'constants/MeasurementType';
import { TFunction } from 'i18next';
import { filter, get, isEmpty, toLower } from 'lodash';
import Measurer from 'models/Measurer';
import { Structure } from '../models/Structure';

const MAINTENANCE_TYPES = [
  MeasurementType.BATTERY_PERCENT,
  MeasurementType.BATTERY,
  MeasurementType.KEEP_ALIVE,
];

export const generateFullName = (measurer: Measurer, t: TFunction): string => {
  return (
    generateRecursiveName(measurer) + ' > ' + getLabelFromMeasurer(measurer, t)
  );
};

export const generateStructureFullName = (
  structure: Structure,
  t: TFunction,
): string => {
  return generateRecursiveName(structure) + ' > ' + structure.locationName;
};

export const filterMaintenanceMeasurers = (measurerList: any) => {
  return filter(
    measurerList,
    (item) => !MAINTENANCE_TYPES.includes(item.measurementType),
  );
};

export const getLabelFromMeasurer = (measurer: Measurer, t: TFunction) => {
  if (!isEmpty(measurer.measurerLabel)) return measurer.measurerLabel;
  return t(`measurementType.${toLower(get(measurer, 'measurementType', ''))}`);
};

const generateRecursiveName = (value: any): string => {
  const parent: string | null = get(
    value,
    'parentLocation',
    get(value, 'location', get(value, 'device')),
  );
  let parentName: string = '';
  if (parent && get(parent, 'locationType') !== LocationType.COMPANY) {
    parentName = generateRecursiveName(parent);
    if (!isEmpty(parentName)) parentName += ' > ';
    return (
      parentName + get(parent, 'deviceName', get(parent, 'locationName', ''))
    );
  }
  return '';
};

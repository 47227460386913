export const es = {
  shared: {
    yes: 'Si',
    no: 'No',
  },
  statDialog: {
    title: 'Estadísticas de medidas: {{date}}',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    average: 'Promedio',
    measurementType: 'Sensores',
  },
  import: 'Importar',
  mapHeaderBanner: {
    sitesCount: '{{sitesCount}} sitios',
    structuresCount: '{{structuresCount}} estructuras equipadas',
    alertsCount: '{{alertsCount}} alertas de umbral activadas',
    structureSelection: 'Seleccionar una Estructura',
    lastMeasurementSeen: 'Últimas mediciones',
    btnNoMeasurementSeen: 'Mediciones',
  },
  navigationMenu: {
    home: 'Inicio',
    sites: 'Sitios',
    companies: 'Empresas',
    measures: 'Mediciones',
    alarms: 'Alarmas',
    devices: 'Dispositivos',
    structures: 'Estructuras',
    measurers: 'Sensores',
    users: 'Usuarios',
    alerts: 'Alertas',
    parcStatus: 'Estado del Parque',
    sitesDetails: 'Detalles del Sitio',
    addSite: 'Agregar un sitio',
    structuresDetails: 'Detalles de la Estructura',
    usersDetails: 'Detalles del Usuario',
    companiesDetails: 'Detalles de la Empresa',
    addCompany: 'Añadir empresa',
    alarmTriggerDetails: 'Detalles de la Activación de Alarma',
    deviceDetails: 'Detalles del Dispositivo',
    device: 'Dispositivo',
    measurersDetails: 'Detalles del Sensor',
    faq: 'Preguntas Frecuentes',
    contact: 'Contacto',
    accountDropDown: {
      accountManagement: 'Gestión de Cuentas',
      logout: 'Cerrar Sesión',
      teamOwner: 'Propietario del Equipo',
    },
  },

  availableLanguages: {
    en: 'Inglés',
    fr: 'Francés',
  },
  logging: {
    login: 'Iniciador de Conexión Solices',
    username: 'Nombre de Usuario',
    password: 'Contraseña',
    loginBtn: 'Iniciar Sesión',
  },
  notification: {
    error: 'Error',
    http_error: {
      err_fr_too_many_redirects:
        'Error del servidor, contacte a su administrador',
      err_bad_option_value: 'Error del servidor, contacte a su administrador',
      err_bad_option: 'Error del servidor, contacte a su administrador',
      err_network: 'Error de conexión, verifique su conexión a internet',
      err_deprecated: 'Error del servidor, contacte a su administrador',
      err_bad_response: 'Error del servidor, contacte a su administrador',
      err_bad_request: 'Error del servidor, contacte a su administrador',
      err_not_support: 'Error del servidor, contacte a su administrador',
      err_invalid_url: 'Error del servidor, contacte a su administrador',
      err_canceled: 'Solicitud cancelada por su navegador',
      econnaborted: 'Error del servidor, contacte a su administrador',
      etimedout:
        'Solicitud cancelada: duración demasiado larga, verifique su conexión a internet',
      unknown_error: 'Error desconocido',
      unauthorized_error: 'Esta acción requiere estar conectado',
      forbidden_error: 'Derechos insuficientes para acceder al recurso',
      notfound_error: 'Recurso no encontrado',
      conflict_error: 'Este elemento ya existe',
    },
    success: 'Éxito',
    create: {
      error: 'Error de creación: ',
      success: 'Creación exitosa',
    },
    update: {
      error: 'Error de actualización: ',
      success: 'Actualización exitosa',
    },
    delete: {
      error: 'Error de eliminación: ',
      success: 'Eliminación exitosa',
    },
  },
  authentication: {
    checkAuthentication: 'Esperando para conectar...',
  },
  datePlaceholder: 'dd/mm/aaaa',
  navigation: {
    goBack: 'Volver',
    validate: 'Validar',
    delete: 'Eliminar',
  },
  device: {
    device: 'Dispositivo',
    deviceIdentifier: 'Identificador',
    deviceName: 'Nombre',
    serialNumber: 'Número de serie',
    iotNetwork: 'Red IoT',
    lastMeasurementDate: 'Última Medición',
    lastMeasurementDurationDetails: 'Duración',
    location: 'Ubicación',
    nbrAlarms: 'Número de Alarmas',
    add: 'Importar un Dispositivo',
    goToChildren: 'Ver Sensores asociados a este dispositivo',
    synchronizeBtn: 'Sincronizar',
    noOptionsText: 'No se encontraron dispositivo',
    synchronizeDialogContent:
      'Esta acción puede tardar varios segundos. Está usted seguro ?',
    explanation1: 'Paso 1: buscar una etiqueta por su número IMEI',
    explanation2:
      'Después de importar la etiqueta, serás redirigido a importar sensores',
    explanation3: 'Paso 3: Finalizar la configuración',
    explanation4:
      'Al presionar el botón Sincronizar, importa el historial de datos y comienza la actualización automática de datos',
  },
  GPRS: {
    iotAddress: 'Dirección GPRS IOT',
  },
  location: {
    company: 'Compañía',
    site: 'Sitio',
    structure: 'Estructura',
    locationName: 'Nombre',
    locationType: 'Tipo',
    parentLocation: 'Ubicación Padre',
    geoLocation: 'Coordenadas Geográficas',
    yLat: 'Latitud',
    xLon: 'Longitud',
    nbrAlarms: 'Número de Alarmas',
    search: 'Ingrese una dirección',
    address: {
      street: 'Calle',
      postalCode: 'Código Postal',
      countryCode: 'País',
      city: 'Ciudad',
      googleFormattedAddress: 'Dirección Completa',
    },
  },
  alarm: {
    measurerId: 'Sensor',
    deviceIdentifier: 'Identificador de Dispositivo',
    measurementType: 'Tipo de Dato',
    eventDate: 'Fecha',
    alarmState: 'Estado',
    add: 'Agregar una Alarma',
    activate: 'Activar una Alarma',
    notifiedEmails: 'Correos electrónicos para notificar',
    notifiedPhoneNumbers: 'Números de teléfono para notificar',
    noAlarm: 'Ninguna alarma',
    deviceName: 'Dispositivo',
    measurementValue: 'Valor',
    thresholdValue: 'Límite',
  },
  alarmTrigger: {
    measurer: 'Sensor',
    thresholdValue: {
      fieldValue: 'Umbral de Alarma',
    },
    hysteresisValue: {
      fieldValue: 'Histéresis',
    },
    boundDirection: 'Tendencia',
    severityLevel: 'Nivel de Alerta',
    synchronize: 'Sincronizar',
  },
  measurer: {
    measurerCategory: 'Categoría',
    import: 'Importar un sensor',
    measurementType: 'Dato Medido',
    alarmState: 'Estado de Alarma',
    device: 'Dispositivo',
    label: 'Leyenda',
    lastAlarmDate: 'Última Alarma',
    measurementDataType: 'Tipo de Dato',
    measurementUnit: 'Unidad de Medida',
    saveAndConfigureNext: 'Guardar y configurar el siguiente sensor',
    saveAndAddNewMeasurer: 'Guardar y agregar otros sensores',
    saveAndFinishConfiguration:
      'Guardar y finalizar la configuración de la etiqueta',
    explanation1: 'Paso 2: Importar paquete de sensores',
    explanation2:
      'Aquí puedes seleccionar grupos de sensores, serás redirigido a la configuración de estos sensores.',
    specificParameters: {
      max_analog_ma: 'Máx. Analógico (mA)',
      min_numeric_meter: 'Mín. Numérico (m)',
      max_numeric_meter: 'Máx. Numérico (m)',
      min_analog_ma: 'Mín. Analógico (mA)',
      reference_void_level: 'Nivel de Vacío de Referencia',
      reference_water_level: 'Nivel de Agua de Referencia',
      battery_full_volt: 'Voltaje de Batería Completa',
      valeur_pleine_echelle: 'Valor de Escala Completa',
      mesure_manuelle: 'Medición Manual',
      reference_ngf_level_meter: 'Nivel de referencia fng (m)',
      reference_water_depth_meter: 'Profundidad del agua de referencia (m)',
      reference_water_height_meter: 'Altura de referencia del agua (m)',
      calibration_water_height_meter: 'Altura del agua de calibración (m)',
      calibration_date: 'Fecha de puesta en servicio',
      longueur_surverse_meter: 'Longitud del rebosadero (m)',
      largeur_canal_meter: 'Ancho del canal (m)',
      hauteur_pelle_meter: 'Altura de la pala (m)',
      coefficient_debit: 'Coeficiente de flujo',
    },
    package: 'Módulo',
    packageName: {
      starter_pack: 'Módulo de Inicio',
      void_level_from_water_level: 'Nivel de Vacío desde Nivel de Agua',
      water_level_analog: 'Nivel de Agua Analógico',
      void_level: 'Nivel de Vacío',
      water_level_from_void_level: 'Nivel de Agua desde Nivel de Vacío',
      temperature: 'Temperatura',
      water_height_relative: 'Altura relativa del agua',
      water_depth_relative: 'Profundidad relativa del agua',
      water_depth_ngf: 'Profundidad del agua ngf',
      water_height_nbf: 'Altura del agua ngf',
      debit_deversoir_noye: 'Flujo de aliviadero inundado',
      debit_deversoir_denoye: 'Flujo de aliviadero seco',
      debit_orifice_denoye: 'Flujo de orificio seco',
    },
    add: 'Importar un sensor',
    goToChildren: 'Ver Alarmas de este Sensor',
  },
  validator: {
    empty: '{{param}} no puede estar vacío',
    enum: 'Dato incorrecto',
    selectLocationLink: 'Debe seleccionar una ubicación padre',
    selectDeviceLink: 'Debe seleccionar un dispositivo',
    selectMeasurer: 'Debe seleccionar un sensor',
    website: 'Debe comenzar con http:// o https://',
  },
  company: {
    identificationNumber: 'Siret',
    companyCode: 'Código',
    legalForm: 'Forma Legal',
    phoneNumber: 'Teléfono',
    email: 'Correo Electrónico',
    website: 'Sitio Web',
    create: {
      error: 'Error al crear la empresa: ',
      success: 'Ha creado una empresa',
    },
    add: 'Agregar una Empresa',
  },
  site: {
    code: 'Código',
    add: 'Agregar un Sitio',
    goToChildren: 'Ver Estructuras vinculadas a este sitio',
  },
  structure: {
    structureCode: 'Código',
    comment: 'Comentario',
    structureType: 'Tipo de Estructura',
    add: 'Agregar una Estructura',
    goToChildren: 'Ver Dispositivos vinculados a esta estructura',
  },
  locationType: {
    structure: 'Estructura',
    company: 'Empresa',
    site: 'Sitio',
  },
  alarmState: {
    ok_no_alarm: 'Sin Alarma',
    upper_bound_minor: 'Límite Superior, Menor',
    upper_bound_major: 'Límite Superior, Mayor',
    lower_bound_minor: 'Límite Inferior, Menor',
    lower_bound_major: 'Límite Inferior, Mayor',
  },
  alarmBoundDirection: {
    upper_bound: 'Límite Superior',
    lower_bound: 'Límite Inferior',
  },
  alarmSeverityLevel: {
    minor: 'Menor',
    major: 'Mayor',
  },
  measurementType: {
    pressure: 'Presión',
    pressure_analog: 'Presión Analógica',
    water_level: 'Nivel de Agua',
    temperature: 'Temperatura',
    humidity: 'Humedad',
    battery: 'Batería',
    battery_percent: 'Porcentaje de Batería',
    network_coordinates: 'Coordenadas de Red',
    keep_alive: 'Mantener Vivo',
    co2: 'CO2',
    cov: 'COV',
    external_temperature_channel_1: 'Temperatura externa canal 1',
    external_temperature_channel_2: 'Temperatura externa canal 2',
    void_level: 'Nivel de vacío',
    water_level_analog: 'Nivel de agua analógico',
    temperature_analog: 'Temperatura analógica',
    water_height: 'Altura del agua',
    water_depth_relative: 'Profundidad',
    water_depth_ngf: 'Altura desde NGF',
    water_height_relative: 'Altura del agua relativa',
    water_height_ngf: 'Altura del agua ngf',
    water_flow: 'Flujo de agua',
  },
  measurerCategory: {
    sensor: 'Sensor',
    calculator: 'Calculadora',
    sounder: 'Estado',
  },
  measurementUnit: {
    degree: 'Grado',
    hectopascal: 'Hectopascal',
    percent: 'Porcentaje',
    volt: 'Voltio',
    lux: 'Lux',
    millimeter: 'Milímetro',
    meter: 'Metro',
    latitude_longitude: 'Latitud Longitud',
    ppm: 'PPM',
    ampere: 'Amperio',
    second: 'Segundos',
  },
  structureType: {
    surface_water: 'Aguas Superficiales',
    groundwater: 'Aguas Subterráneas',
    conduct: 'Conducción',
  },
  user: {
    login: 'Inicio de Sesión',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo Electrónico',
    secondEmail: 'Segundo Correo Electrónico',
    mobilePhoneNumber: 'Teléfono Móvil',
    fixedPhoneNumber: 'Teléfono Fijo',
    code: 'Código',
    company: 'Empresa',
    createdDate: 'Fecha de Creación',
    lastModifiedDate: 'Fecha de Última Modificación',
    createdBy: 'Creado Por',
    lastModifiedBy: 'Última Modificación Por',
    activated: 'Activado',
    langKey: 'Idioma',
    activatedState: {
      true: 'Sí',
      false: 'No',
    },
    authorities: 'Papel',
    add: 'Agregar un Usuario',
    user: 'Usuario',
    admin: 'Administrador',
    manager: 'Gerente',
    location: 'Sitio/Estructura',
    addUserLocation: 'Agregar',
    permissions: 'Permisos',
    permission: {
      create: 'Crear',
      read: 'Leer',
      update: 'Actualizar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      all: 'Todos',
    },
  },
  dataGrid: {
    action: 'Acción',
  },
  modal: {
    deleteTitle: 'Confirmar la eliminación',
    confirmDelete: '¿Estás segura de que quieres eliminar este artículo?',
  },
  measurement: {
    filterPeriod: {
      last7days: '7d',
      lastWeek: '1s',
      lastMonth: '-1m',
      lastSemester: '6m',
      lastYear: '1año',
      aec: 'aec',
      max: 'max',
    },
    dateRange: {
      beginDate: 'Fecha de Inicio',
      endDate: 'Fecha de Fin',
    },
    deviceIdentifier: 'Dispositivos',
    deviceName: 'Dispositivos',
    measurementType: 'Tipo',
    period: 'Fecha',
    measurementDate: 'Fecha',
    measurerValue: 'Valor',
    averageValue: 'Valor',
    measurer: 'Sensor',
    chooseMeasurer: 'Elegir un Sensor',
    validate: 'Validar',
    seeGraph: 'Ver Gráfico',
    seeChronicles: 'Ver Crónicas',
    compareMeasures: 'Comparar medidas',
    statistics: 'Estadísticas',
    gotoMap: 'Mapa',
  },
  googleMap: {
    satellite: 'Capa satélite',
    infoWindow: {
      goTo: 'Ver Sitio',
    },
  },
  pwa: {
    calibration_date: 'Calibración',
    updateFrequency: 'Frecuencia de envío (horas)',
  },
  image: {
    fileName: 'Apellido',
    mediaType: 'Amable',
    upload: 'Añadir',
    download: 'Descargar',
    delete: 'Tachar',
  },
};
